


import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Unsubscribe extends Vue {

  public eventId: number = null;
  public contactId: number = null;
  public emailString: string = '';
  public hashString: string = '';
  public success: boolean = false
  public error: Error = null;

  public isEventSummaryEmailAllowed: boolean = false;
  public isNewMeetingRequestEmailAllowed: boolean = false;
  public isMeetingReminderEmailAllowed: boolean = false;
  public isMeetingConfirmEmailAllowed: boolean = false;
  public isNewMessageEmailAllowed: boolean = false;

  public mounted(): void {
    this.eventId = parseInt(this.$route.params.eventId, 10);
    this.contactId = parseInt(this.$route.params.contactId, 10);
    this.emailString = this.$route.params.emailString;
    this.hashString = this.$route.params.hashString;
  }

  public toggleAllowEventSummary(): void {
    this.isEventSummaryEmailAllowed = !this.isEventSummaryEmailAllowed;
  }

  public toggleAllowNewMeetingRequest(): void {
    this.isNewMeetingRequestEmailAllowed = !this.isNewMeetingRequestEmailAllowed;
  }

  public toggleAllowMeetingReminder(): void {
    this.isMeetingReminderEmailAllowed = !this.isMeetingReminderEmailAllowed;
  }

  public toggleAllowMeetingConfirm(): void {
    this.isMeetingConfirmEmailAllowed = !this.isMeetingConfirmEmailAllowed;
  }

  public toggleAllowNewMessage(): void {
    this.isNewMessageEmailAllowed = !this.isNewMessageEmailAllowed;
  }

  public async sendUnsubscribeRequest(): Promise<void> {
    const unsubscribeRequest = await this.$store.dispatch('mailSettingsStore/unsubscribeRequest', {
      event_id: this.eventId,
      contact_id: this.contactId,
      email: this.emailString,
      hash: this.hashString,
      allow_event_summary: !this.isEventSummaryEmailAllowed,
      allow_new_meeting_request: !this.isNewMeetingRequestEmailAllowed,
      allow_meeting_reminder: !this.isMeetingReminderEmailAllowed,
      allow_meeting_confirm: !this.isMeetingConfirmEmailAllowed,
      allow_new_message: !this.isNewMessageEmailAllowed,
    });

    if (unsubscribeRequest.status && unsubscribeRequest.status === 202) {
      this.success = true;
    }

    if (unsubscribeRequest.error) {
      this.success = false;
      this.error = unsubscribeRequest.error;
    }
  }
}
